<template>
  <div class="home">
    <div class="header">
      <div class="w head_box">
        <div class="logo">
          <img class="logo" src="@/assets/logo.png" alt="">
        </div>
        <div class="tab_box">
          <div class="item" @click="change1" :class="activeName==1?'active':''">
            Home</div>
          <div class="item" @click="change2" :class="activeName==2?'active':''">
            Product</div>
          <div class="item" @click="change3" :class="activeName==3?'active':''">
            Contact us</div>
        </div>
      </div>
    </div>
    <div class="mian_home" v-if="activeName==1">
      <div class="swiper">
        <el-carousel height='480px' autoplay loop>
          <el-carousel-item v-for="(item,k) in swiperList" :key="k">
            <img @click="parse(item,k)" style="width:100%;height:100%"
              :src="item.image2" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="ARRIVALS w" v-if="hotList.length!==0">
        <div class="title">NEW ARRIVALS</div>
        <div class="item_box">
          <div class="img_boxs" :key='k' v-for="(item,k) in hotList"
            @click="parse(item,k)">
            <img class="img" :src="item.image" alt="">
            <div style="font-size:18px">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="Concepts">
        <div class="box w">
          <div class="text_content">
            <div class="position1">
              <div class="title1">{{PC1.title}}</div>
              <div class="t"></div>
            </div>
            <div class="text">
              {{PC1.descs}}
            </div>
          </div>
          <div class="img_content">
            <img class="img" :src="PC1.image" alt="">
          </div>
        </div>
        <div class="box w" style="margin-top:120px;margin-bottom:120px">
          <div class="img_content">
            <img class="img" :src="PC2.image" alt="">
          </div>
          <div class="text_content">
            <div class="position1">
              <div class="title1">{{PC2.title}}</div>
              <div class="t"></div>
            </div>
            <div class="text">
              {{PC2.descs}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mian_Product w" v-if="activeName==2">
      <div class="tab ">
        <div class="item" @click="tabChangeAll"
          :class="TactiveName==-1?'Tactive':''">ALL</div>
        <div class="item" @click="tabChange(item,k)"
          :class="TactiveName==k?'Tactive':''" :key='k'
          v-for="(item,k) in tabList">{{item.name}}</div>
      </div>
      <div class="goods_box">
        <div class="item" :key='k' v-for="(item,k) in goodsList"
          @click="parse(item)">
          <img class="img" :src="item.image" alt="">
          <div class="info">
            <div class="x1">{{item.name}}</div>
            <!-- <div class="x2">￥{{item.price}}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mian_Contactus w" v-if="activeName==3">
      <img src="@/assets/bg.png" class="img" alt="">
      <div class="content w" v-html="parses">

      </div>
    </div>
    <div class="mian_Dtl w" v-if="activeName==4">
      <div class="util" v-if="show">The current position: <span
          @click="goHome">Home</span>
        > Product details</div>
      <!-- 富文本 -->
      <div class="content w" v-html="parses">

      </div>
    </div>
    <div class="footer" :class="activeName==1?'changeFooter':''">
      <div class="w footer_">
        <div class="top">
          <img class="img" src="@/assets/address.jpg" alt="">
          <div class="info">
            <div class="item1">{{obj.companyname}}</div>
            <div class="item">Head Office:{{obj.companyaddress}}</div>
            <div class="item">Contact phone:{{obj.phone}}</div>
            <div class="item">E-Mail: {{obj.companyemail}}</div>
            <div class="item">Whats: {{obj.weixin}}</div>
          </div>
        </div>
        <div class="bottom">Copyright c 2021 {{obj.companyname}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
      activeName: 1,
      TactiveName: -1,
      swiperList: [

      ],
      hotList: [],
      PC1: {},
      PC2: {},
      tabList: [

      ],
      parses: '',
      show: true,
      cid: '',
      goodsList: [],
      obj: {}

    }
  },
  created () {
    this.init()
    this.init1()
    this.init2()
    this.init4()
    this.init8()
  },
  mounted () {
    window.addEventListener('scroll', this.top)
  },
  methods: {
    async init () {
      const { data: res } = await this.$http.post('bannerlist', {})
      this.swiperList = res.dataList
    },
    async init8 () {
      const { data: res } = await this.$http.post('getaboutus', {})
      this.obj = res.dataobject
    },
    async init1 () {
      const { data: res } = await this.$http.post('gettuiequipmentlist', {})
      this.hotList = res.dataList
    },
    async init2 () {
      const { data: res } = await this.$http.post('getintroduceslist', {})
      console.log(res, 'fshdjfiohsdioafhios')
      if (res.dataList !== 0) {
        this.PC1 = res.dataList[0]
        this.PC2 = res.dataList[1]
      }
    },
    async init3 () {
      const { data: res } = await this.$http.post('getclassiflist', {})
      this.tabList = res.dataList
    },
    async init5 () {
      const { data: res } = await this.$http.post('getagreement', { type: 2 })
      this.parses = res.content
    },
    async init4 () {
      const query = {
        cid: this.cid,
        nowPage: 1,
        pageCount: 99999
      }
      const { data: res } = await this.$http.post('getequipmentlist', query)
      console.log(res)
      this.goodsList = res.dataList
    },
    change1 () {
      this.activeName = 1
    },
    change2 () {
      this.init3()
      this.activeName = 2
    },
    change3 () {
      this.init5()
      this.activeName = 3
    },
    tabChangeAll () {
      this.cid = ''
      this.goodsList = []
      this.init4()
      this.TactiveName = -1
    },
    tabChange (item, k) {
      console.log(item)
      this.cid = item.cid
      this.goodsList = []
      this.init4()
      this.TactiveName = k
    },
    goDtl () {
      this.activeName = 4
    },
    goHome () {
      this.activeName = 1
    },
    parse (item, k) {
      this.show = false
      this.parses = item.content
      this.activeName = 4
    },
    top (e) {
      var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop
      if (scrollTop > Number(1)) {
        document.getElementsByClassName('header')[0].classList.add('el-tabs__headers')
      } else if (scrollTop < Number(105)) {
        document.getElementsByClassName('header')[0].classList.remove('el-tabs__headers')
      }
    }
  }
}
</script>
<style  lang='less' scoped>
.swiper {
  cursor: pointer;
}
.el-tabs__headers {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.home {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}
.active {
  font-size: 18px;
  color: #ea0a2a;
}
.header {
  width: 100%;
  padding: 20px 0;
  background-color: #fff !important;
  .head_box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 162px;
      height: 81px;
    }
    .tab_box {
      display: flex;
      align-items: center;
      .item {
        width: 230px;
        text-align: right;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
.mian_home {
  background-color: #f2f2f2 !important;

  .ARRIVALS {
    padding-bottom: 100px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ea0a2a;
      margin: 60px 0;
    }
    .item_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img_boxs {
        cursor: pointer;
        padding-top: 20px;
        background-color: #fff;
        width: 300px;
        height: 350px;
      }
      .img {
        width: 250px;
        height: 250px;
        margin-bottom: 40px;
      }
    }
  }
  .Concepts {
    background-color: #fff;
    padding: 50px 0;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text_content {
        text-align: left;
        width: 500px;
        .position1 {
          position: relative;
          .title1 {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ea0a2a;
          }
          .t {
            width: 38px;
            height: 3px;
            border-radius: 1px;
            background: #ea0a2a;
            position: absolute;
            bottom: -6px;
            left: 0px;
          }
        }
        .text {
          margin-top: 20px;
          font-size: 18px;
          color: #959595;
        }
      }
      .img_content {
        .img {
          width: 650px;
          height: 350px;
        }
      }
    }
  }
}
.mian_Product {
  padding-bottom: 100px;
  .Tactive {
    border-bottom: 2px solid #ea0a2a;
    color: #ea0a2a !important;
  }
  .tab {
    padding: 30px 0;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    .item {
      position: relative;
      font-size: 18px;
      color: #9b9b9b;
      margin-right: 100px;
      padding-bottom: 15px;
      cursor: pointer;
    }
  }
  .goods_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .item {
      width: 390px;
      height: 470px;
      cursor: pointer;
      margin-right: 10px;
      .img {
        width: 390px;
        height: 390px;
        border-radius: 10px;
      }
      .info {
        text-align: left;
        .x1 {
          color: #333;
          font-size: 16px;
          width: 390px;
          margin: 8px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .x2 {
          color: #ea0a2a;
          font-family: PingFang SC;
        }
      }
    }
    .item:nth-child(3) {
      margin-right: 0;
    }
  }
}
.mian_Contactus {
  .img {
    width: 100%;
    height: 480px;
    margin-bottom: 50px;
  }
}
.mian_Dtl {
  .util {
    text-align: left;
    padding: 30px 0;
    color: #666666;
    span {
      cursor: pointer;
      color: #ea0a2a;
    }
  }
}
.changeFooter {
  background-color: #f2f2f2 !important;
}
.footer {
  padding: 40px 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  .footer_ {
    .top {
      padding: 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img {
        width: 500px;
        height: 240px;
      }
      .info {
        margin-left: 30px;
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item1 {
          text-align: left;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .item {
          text-align: left;

          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      margin-top: 30px;
    }
  }
}
</style>
